<template>
  <section class="store-info">
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th>
              <div class="bg">商铺</div>
            </th>
            <th>
              <div class="bg">核销量</div>
            </th>
            <th>
              <div class="bg">占比</div>
            </th>
          </tr>
        </thead>
        <tbody class="store-list-wrap" :class="{ 'animate-up': animateUp }">
          <tr v-for="(person, i) in persons" :key="i">
            <td>
              <div class="bg2">
                {{ person.name }}
              </div>
            </td>
            <td>
              <div class="bg2">{{ person.verify_number }}</div>
            </td>
            <td>
              <div class="bg2">
                {{ person.verify_rate }}%
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
// import { getCouponStat } from "@/api/api.js";

export default {
  data() {
    return {
      animateUp: false,
      timer: null,
      persons: [
        { name: "米兰站", verify_number: "24", verify_rate: "20%" },
        { name: "意大利进口馆", verify_number: "48", verify_rate: "40%" },
        { name: "贝易", verify_number: "32", verify_rate: "25%" },
        { name: "CONTE DI MILANO", verify_number: "87", verify_rate: "67%" },
        { name: "塞尔维亚黑山馆", verify_number: "13", verify_rate: "29%" },
      ],
    };
  },
  methods: {
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.persons.push(this.persons[0]);
        this.persons.shift();
        this.animateUp = false;
      }, 500);
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    // getCouponStat().then((res) => {
    //   this.persons = res.data.data;
    // });
    this.timer = setInterval(this.scrollAnimate, 3000);
  },
};
</script>
<style scoped>
.store-info table {width:100%;color:#fff;margin-top:18px;}
.store-info table .bg {
  background: #14282e;
  padding: 8px;
  margin-bottom: 3px;
}

.store-info table .bg2 {
  background: rgba(12, 28, 34, 0.7);
  padding: 8px;
  margin-bottom: 3px;
}


.store-info table td:first-child .bg2,
.store-info table th:first-child .bg {
  border-radius: 10px 0 0 10px;
}

.store-info table td:last-child .bg2,
.store-info table th:last-child .bg {
  border-radius: 0 10px 10px 0;height: 36px;
}

.store-info table td,
.store-info table thead th {
  padding: 0;text-align:center
}

.store-list {
  /* height: 220px; */
  overflow: hidden;
  font-size: 14px;
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
</style>
