<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";

import { getCouponUsage } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        legend: {
          type: "plain",
          orient: "vertical",
          right: "5%",
          top: "5%",
          textStyle: {
            color: "#a3fffe",
            fontSize: "12",
          },
          itemHeight: 12,
          itemWidth: 12,
          itemGap: 10,
        },
        tooltip: {
          trigger: "item",
        },
        series: {
          name: "本月优惠券情况",
          type: "pie",
          radius: [20, 30],
          center: ["30%", "50%"],
          roseType: "area",
          data: this.newData,
          left: "0%",
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: '{c}'
              }
            }
          },
        },
      },
    };
  },
  methods: {
    getData() {
      getCouponUsage().then((res) => {
        const data = res.data.data
        this.option.series.data = [
          {name: '本月优惠券领取数量', value: data.current_month_voucher_receive_count},
          {name: '本月优惠券使用数量', value: data.current_month_voucher_verify_count	},
        ]
      });
    }
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 6000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
</style>
