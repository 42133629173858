<template>
  <section class="store-info">
    <div class="store-list">
      <table>
        <tbody class="store-list-wrap">
          <tr class="bg2">
            <td><div class="bg2">当天注册:</div></td>
            <td width="20%"><div class="bg2">{{info.today_register}}</div></td>
            <td><div class="bg2">昨日注册:</div></td>
            <td><div class="bg2">{{info.yesterday_register}}</div></td>
          </tr>
          <tr class="bg2">
            <td><div class="bg2">当日会员消费额:</div></td>
            <td width="20%"><div class="bg2">{{info.today_consume}}</div></td>
            <td><div class="bg2">当日会员消费比率:</div></td>
            <td><div class="bg2">{{info.consume_ratio}}％</div></td>
          </tr>
          <tr class="bg2">
            <td><div class="bg2">当日活跃会员:</div></td>
            <td width="20%"><div class="bg2">{{info.today_active}}</div></td>
            <td><div class="bg2">当日活跃会员比率:</div></td>
            <td><div class="bg2">{{info.today_active_ratio}}％</div></td>
          </tr>
          <tr class="bg2">
            <td><div class="bg2">昨日活跃会员:</div></td>
            <td width="20%"><div class="bg2">{{info.yesterday_active}}</div></td>
            <td><div class="bg2">昨日会员消费额:</div></td>
            <td><div class="bg2">{{info.yesterday_consume}}</div></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { getMemberInfo } from "@/api/api.js";

export default {
  data() {
    return {
      timer: null,
      info: {}
    };
  },
  methods: {
    getData() {
      getMemberInfo().then((res) => {
        this.info = res.data.data;
      });
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.scrollAnimate, 6000)
  },
};
</script>
<style scoped>
p {
  font-size: 20px;
  color: #fff;
}
.store-info table {width:100%;color:#fff;margin-top:18px;}
.store-info table .bg {
  background: #15363b;
  padding: 8px;
  margin-bottom: 3px;
}

.store-info table .bg2 {
  background: rgba(14, 42, 48, 0.7);
  padding: 8px;
  margin-bottom: 3px;
}

.store-info table td:first-child .bg2,
.store-info table th:first-child .bg {
  border-radius: 10px 0 0 10px;
}

.store-info table td:last-child .bg2,
.store-info table th:last-child .bg {
  border-radius: 0 10px 10px 0;
  height: 45px;
}

.store-info table td,
.store-info table thead th {
  padding: 0;text-align:center
}

.store-info .event-icon {
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.store-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 16px;
}

.store-list {
  height: 220px;
  overflow: hidden;
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
</style>
