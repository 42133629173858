<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
  import {
    use
  } from "echarts/core";
  import {
    CanvasRenderer
  } from "echarts/renderers";
  import {
    BarChart,
    LineChart
  } from "echarts/charts";

  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
  } from "echarts/components";

  import { getStoreTurnover } from "@/api/api.js";
  use([
    CanvasRenderer,
    BarChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
  ]);
  export default {
    components: {},
    data() {
      return {
        option: {
          tooltip: {},
          legend: {
            data: ["销售额"],
            right: 0,
            textStyle: {
              color: "#a3fffe",
              fontSize: "14",
            },
          },
          xAxis: [{
            type: "category",
            data: ["昨天", "今天"],
            axisPointer: {
              type: "shadow",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#a3fffe",
              },
            },
            axisTick: {
              show: false
            }
          }, ],
          yAxis: [{
              type: "value",
              // min: 0,
              // max: 10000,
              // interval: 1000,
              axisLabel: {
                formatter: "{value}",
              },
              axisLine: {
                lineStyle: {
                  color: "#a3fffe",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#114556",
                  type: "dotted"
                }
              }
            },
            {
              type: "value",
              show: false,
              min: 0,
              max: 200,
              interval: 40,
              axisLabel: {
                formatter: "{value}",
              },
            },
          ],
          series: [
            // {
            //   name: "订单",
            //   type: "bar",
            //   data: [0, 0],
            //   itemStyle: {
            //     color: {
            //       type: "linear",
            //       x: 0,
            //       y: 0,
            //       x2: 0,
            //       y2: 1,
            //       colorStops: [{
            //           offset: 0,
            //           color: "#9b65e5",
            //         },
            //         {
            //           offset: 1,
            //           color: "#1f2949",
            //         },
            //       ],
            //       globalCoord: false,
            //     },
            //   },
            // },
            {
              name: "销售额",
              type: "bar",
              data: [0, 0],
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                },
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0,
                      color: "#47adf5",
                    },
                    {
                      offset: 1,
                      color: "#143a53",
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
            // {
            //   name: "人流",
            //   type: "bar",
            //   data: [0, 0],
            //   itemStyle: {
            //     color: {
            //       type: "linear",
            //       x: 0,
            //       y: 0,
            //       x2: 0,
            //       y2: 1,
            //       colorStops: [{
            //           offset: 0,
            //           color: "#52f86b",
            //         },
            //         {
            //           offset: 1,
            //           color: "#164933",
            //         },
            //       ],
            //       globalCoord: false,
            //     },
            //   },
            // },
          ],
        },
      };
    },
    methods: {
      dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
      },
      getData() {
        getStoreTurnover().then((res) => {
          let data = res.data.data
          let todayStr = this.dateFormat("YYYY-mm-dd 00:00:00", new Date())
          const todayOrder = data.order.find((item) => { return item.time == todayStr }) || {sum_count: 0, sum_price: 0}
          const yesterdayOrder = data.order.find((item) => { return item.time != todayStr }) || {sum_count: 0, sum_price: 0}
          this.option.series[0].data = [yesterdayOrder.sum_price, todayOrder.sum_price]
        });
      },
    },
    mounted() {
      this.getData()
      this.timer = setInterval(this.getData, 6000);
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
  };
</script>

<style scoped>
</style>
